angular.module("app")
    .controller("RootController", function ($scope, _view, CONFIG) {
        $scope.view = _view;

        // var s = document.createElement("script");
        // s.type = "text/javascript";
        // s.async = true;
        // s.src = '//api.usersnap.com/load/'+CONFIG.userSnapAPIKey + '.js';
        // var x = document.getElementsByTagName('script')[0];
        // x.parentNode.insertBefore(s, x);
    });